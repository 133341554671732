import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";

const UsersList = () => {
  const navigate = useNavigate();
  const tableHeading = [
    "name",
    "role",
    "gender",
    "status",
    "Action",
  ];
  const [userList, setUserList] = useState([]);
  // eslint-disable-next-line
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  //PAGINATION STATE
  const [searchTxt, setsearchTxt] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [limit, setLimit] = useState(10);
  // setLimit(LIMIT);
  const limit = LIMIT
  const [numberOfTotalData, setNumberOfTotalData] = useState(0);
  const [totalPagesNumber, setTotalPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timeout;
    if (!!searchTxt) {
      timeout = setTimeout(() => {
        getListOfUsers();
      }, 1500);
    } else {
      getListOfUsers();
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchTxt, currentPage]);

  const getListOfUsers = async () => {
    const { data, success, total, totalPages } = await new APIServices(
      "admin-user/list"
    ).post({
      page: currentPage,
      limit: limit,
      search: searchTxt,
    });

    if (success) {
      setIsLoading(false);
      setUserList(data);
      setTotalPageNumber(totalPages || 0);
      setNumberOfTotalData(total || 0);
    }
  };

  const handleDeleteUser = async () => {
    const { message, success } = await new APIServices(
      `admin-user/${selectedUserId}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      getListOfUsers();
    }
  };

  const handleEditUser = (id) => {
    navigate(`/admin-users/edit/${id}`);
  };

  const handleView = (id) => {
    navigate(`/admin-users/view/${id}`);
  };

  const navigateToAddNewUser = () => {
    navigate("/admin-users/add");
  };

  const handleTableSearch = (val) => {
    setsearchTxt(val);
  };

  return (
    <section className="dark:bg-gray-800 ">
      <div className="px-4 py-4 mx-auto lg:py-8 md:px-6">
        <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <TableEntry
                title="List of Admin Users"
                navigate={navigateToAddNewUser}
                handleTableSearch={handleTableSearch}
              />
              <CustomTable
                tableData={userList}
                tableHeadingData={tableHeading}
                handleDelete={false}
                handleEdit={handleEditUser}
                handleView={handleView}
                actionField="_id"
              />
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPagesNumber={totalPagesNumber}
                numberOfTotalData={numberOfTotalData}
                limit={limit}
              />
            </>
          )}
        </div>
      </div>
      {showModal && (
        <DeleteModal handleDelete={handleDeleteUser} close={setShowModal} />
      )}
    </section>
  );
};

export default UsersList;
