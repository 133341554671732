import React, { useState, useEffect } from "react";
import APIServices from "../../../httpServices/httpServices";
import { useNavigate, useParams } from "react-router-dom";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
import DeleteModal from "../../common/modals/DeleteModal";

const ViewAdInventory = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (id) {
            getDetailView();
        }
        // eslint-disable-next-line
    }, [id]);

    // Fetch User Data
    const getDetailView = async () => {
        const { data, success } = await new APIServices(`ad-inventory/${id}`).get();
        if (success) {
            setData(data);
        }
    };

    const handleShowDeleteModal = (id) => {
        setShowModal(true);
    };

    const handleDeleteRole = async () => {
        const { message, success } = await new APIServices(`ad-inventory/${id}`).delete();
        if (success) {
            setShowModal(false);
            emitSuccessToast(message);
            navigate("/ad-inventory");
        }
    };

    const { _id, title, imageUrl, description, destinationUrl, ctaText, adFormat, adClient, adSlot, fullWidthResponsive, displayStyle, customCSS, customJS, adPlacement, priority, visibility, clickTracking } = data;

    return (
        <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <div className="flex justify-between mt-5 mb-4 items-end">
                <h2 className="font-bold text-lg"> Ad Inventory  Information </h2>

            </div>
            <div className="shadow bg-white border-gray-500 p-8 rounded">
                <div className="flex flex-wrap">
                    <DataView label="title" value={title} />
                    <DataView label="imageUrl" value={imageUrl} />
                    <DataView label="description" value={description} />
                    <DataView label="destinationUrl" value={destinationUrl} />
                    <DataView label="ctaText" value={ctaText} />
                    <DataView label="adFormat" value={adFormat} />
                    <DataView label="adClient" value={adClient} />
                    <DataView label="adSlot" value={adSlot} />
                    <DataView label="fullWidthResponsive" value={fullWidthResponsive} />
                    <DataView label="displayStyle" value={displayStyle} />
                    <DataView label="customCSS" value={customCSS} />
                    <DataView label="customJS" value={customJS} />
                    <DataView label="adPlacement" value={adPlacement} />
                    <DataView label="priority" value={priority} />
                    <DataView label="visibility" value={visibility} />
                    <DataView label="clickTracking" value={clickTracking} />
                </div>
            </div>
            {showModal && (
                <DeleteModal handleDelete={handleDeleteRole} close={setShowModal} />
            )}
        </div>
    );
};

export default ViewAdInventory;
