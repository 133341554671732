import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import APIServices from "../../../httpServices/httpServices";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";
import { Card, Container, Grid, Typography, Skeleton, Box } from "@mui/joy";

const WritingQuestionList = ({ writingGroupId }) => {
    const navigate = useNavigate();
    const tableHeading = ["QuestionNo", "questionType", "question", "wordLimit", "timeLimit", "status", "Action"];
    const [dataListToShowOnTable, setDataListToShowOnTable] = useState([]);

    //PAGINATION STATE
    const [searchTxt, setsearchTxt] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const limit = LIMIT;

    const [numberOfTotalData, setNumberOfTotalData] = useState(0);
    const [totalPagesNumber, setTotalPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let timeout;
        if (!!searchTxt) {
            timeout = setTimeout(() => {
                getListOfDataToShowOnTable();
            }, 1500);
        } else {
            getListOfDataToShowOnTable();
        }
        return () => clearTimeout(timeout);
        // eslint-disable-next-line
    }, [searchTxt, currentPage, writingGroupId]);



    const getListOfDataToShowOnTable = async () => {
        const { data, success, total, totalPages } = await new APIServices(
            `writing-question/list`
        ).post({
            page: currentPage,
            limit: limit,
            search: searchTxt,
            writingGroupId: writingGroupId
        });

        if (success) {
            setIsLoading(false);
            setDataListToShowOnTable(
                data.map((request) => ({
                    ...request,
                    QuestionNo: request?.orderBy
                }))
            );

            setTotalPageNumber(totalPages || 0);
            setNumberOfTotalData(total || 0);
        }
    };


    const handleEdit = (id) => {
        navigate(`/writing-question/edit/${id}`);
    };

    const handleView = (id) => {
        navigate(`/writing-question/view/${id}`)
    };
    const navigateToAddNew = () => {
        navigate(`/writing-question/${writingGroupId}`);
    };

    const handleTableSearch = (val) => {
        setsearchTxt(val);
    };

    return (

        <div className="w-full">    
          
            <Grid container spacing={0}>
                {isLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={400} />
                ) : (
                    <>
                    
                        <Grid xs={12}>
                            <TableEntry
                                title="List of Writing Questions"
                                  
                                navigate={!writingGroupId ? navigateToAddNew : ""}
                                handleTableSearch={handleTableSearch}
                            />
                        </Grid>
                        
                        <Grid xs={12}>
                            <Card variant="plain" sx={{ overflowX: 'auto' }}>
                                <CustomTable
                                    tableData={dataListToShowOnTable}
                                    tableHeadingData={tableHeading}
                                    handleDelete={false}
                                    handleEdit={handleEdit}
                                    handleView={handleView}
                                    actionField="_id"
                                />
                            </Card>
                        </Grid>

                        <Grid xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <TablePagination
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    totalPagesNumber={totalPagesNumber}
                                    numberOfTotalData={numberOfTotalData}
                                    limit={limit}
                                />
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>

    );
};

export default WritingQuestionList;
