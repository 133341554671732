// src/theme/defaultTheme.js
import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif", // Set global font family
    htmlFontSize: 16, // Base font size (default is 16px)
    fontSize: 14, // Default font size for body text
    h1: {
      fontSize: '2.5rem', // 40px
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem', // 32px
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.75rem', // 28px
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.5rem', // 24px
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.25rem', // 20px
      fontWeight: 400,
    },
    h6: {
      fontSize: '1rem', // 16px
      fontWeight: 400,
    },
    body1: {
      fontSize: '1rem', // 16px
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem', // 14px
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '0.875rem', // 14px
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
    },
    button: {
      fontSize: '0.875rem', // 14px
      fontWeight: 600,
      textTransform: 'none', // Prevents uppercase text
    },
    typography: {
      'title-sm': {
        fontSize: '0.75rem', // Set it to small (12px)
        fontWeight: 500, // Adjust weight if needed
      },
    },
  },
});

export default defaultTheme;
