import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { v4 as uuid } from "uuid";
import {
  Table,
  Sheet,
  Checkbox,
  Chip,
  IconButton,
  Button,
  Typography,
  Box,
  Stack
} from "@mui/joy";
import {
  ArrowDropUp,
  ArrowDropDown,
  Visibility,
  Edit,
  Delete,
  Add,
  Check,
  Close
} from "@mui/icons-material";

const CustomTable = ({
  tableHeadingData = [],
  tableData = [],
  handleDelete,
  handleEdit,
  handleAccept,
  handleReject,
  handleClick,
  categorySlug,
  handleChildCategory,
  handleChildCategoryView,
  handleEditSubCategory,
  handleView = undefined,
  handleReport = undefined,
  actionField,
  hasSelectAllFeature = false,
  handleSorting,
  sort,
  titleLink,
  handlePreviewQuestion,
  Id
}) => {
  const { t } = useTranslation();

  const renderCellContent = (heading, item) => {
    switch (true) {
      case heading === "status":
        return (
          <Chip
            variant="soft"
            color={item[heading] === "Active" ? "success" : "danger"}
            startDecorator={
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  bgcolor: item[heading] === "Active"
                    ? "success.500"
                    : "danger.500"
                }}
              />
            }
          >
            {t(item[heading])}
          </Chip>
        );

      case heading === "RequestedDate":
        return moment(item?.Date).format("YYYY-MM-DD");

      case heading === "Child" && item[heading].length > 0:
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleView(item[actionField])}
            endDecorator={
              <Chip variant="solid" color="danger" size="sm">
                {item[heading].length}
              </Chip>
            }
          >
            {t('View Sub Categories')}
          </Button>
        );

      case heading === "Children":
        const modifiedHeading = "Child";
        return item[modifiedHeading].length > 0 ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleChildCategoryView(categorySlug, item[actionField])}
            endDecorator={
              <Chip variant="solid" color="danger" size="sm">
                {item[modifiedHeading].length}
              </Chip>
            }
          >
            {t('View Child Categories')}
          </Button>
        ) : (
          t('No Child Categories')
        );

      case heading === "Action":
        return (
          <Stack direction="row" spacing={1}>
            {handlePreviewQuestion && (
              <Button
                variant="solid"
                color="primary"
                onClick={() => handlePreviewQuestion(item[actionField], item.title)}
              >
                {t('Prepare Questions')}
              </Button>
            )}

            {handleView && (
              <IconButton
                variant="plain"
                color="neutral"
                onClick={() => handleView(item[actionField])}
              >
                <Visibility />
              </IconButton>
            )}

            {handleEdit && (
              <IconButton
                variant="plain"
                color="primary"
                onClick={() => handleEdit(item[actionField])}
              >
                <Edit />
              </IconButton>
            )}

            {handleAccept && (
              <Button
                variant="solid"
                color="success"
                startDecorator={<Check />}
                onClick={() => handleAccept(item[actionField])}
              >
                {t('Approve')}
              </Button>
            )}

            {handleReject && (
              <Button
                variant="solid"
                color="danger"
                startDecorator={<Close />}
                onClick={() => handleReject(item[actionField])}
              >
                {t('Reject')}
              </Button>
            )}

            {handleDelete && (
              <IconButton
                variant="plain"
                color="danger"
                onClick={() => handleDelete(item[actionField])}
              >
                <Delete />
              </IconButton>
            )}
          </Stack>
        );

      case ["PermissionValue", "SettingValue"].includes(heading):
        return !!item[heading] ? (
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {JSON.parse(item[heading]).slice(0, 5).map((val) => (
              <Chip key={uuid()} variant="outlined" size="sm">
                {val}
              </Chip>
            ))}
            {JSON.parse(item[heading]).length > 5 && "..."}
          </Stack>
        ) : "--";

      case heading === "Date":
        return moment(item?.Date).format("YYYY/MM/DD");

      case heading === "Title":
        return titleLink ? (
          <Typography
            component={Link}
            to={`/${titleLink}/${item?._id}`}
            color="primary"
            sx={{ textDecoration: 'underline' }}
          >
            {item[heading]?.toString() || "--"}
          </Typography>
        ) : item[heading]?.toString() || "--";

      default:
        return item[heading]?.toString() || "--";
    }
  };

  return (
    <Sheet variant="outlined" sx={{
      width: '100%',
      borderRadius: 'sm',
      overflow: 'auto',
      background: 'none'
    }}>
      <Table sx={{ minWidth: 800, tableLayout: 'auto' }}>
        <thead>
          <tr>
            {tableHeadingData.map((item, index) => (
              <th key={uuid()} style={{ padding: '12px 16px' }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ...(handleSorting && { cursor: 'pointer' })
                }}>
                  {index === 0 && hasSelectAllFeature && (
                    <Checkbox sx={{ mr: 2 }} />
                  )}

                  <Typography level="body-sm">
                    {t(item.replace(/([a-z](?=[A-Z]))/g, "$1 "))}
                  </Typography>

                  {handleSorting && (
                    <IconButton
                      size="sm"
                      variant="plain"
                      onClick={() => handleSorting(item)}
                      sx={{ ml: 1 }}
                    >
                      {item in sort ? (
                        sort[item] === 1 ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )
                      ) : (
                        <>
                          <ArrowDropUp sx={{ mb: -0.5 }} />
                          <ArrowDropDown sx={{ mt: -0.5 }} />
                        </>
                      )}
                    </IconButton>
                  )}
                </Box>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {tableData.length ? (
            tableData.map((item) => (
              <tr key={uuid()}>
                {tableHeadingData.map((heading, index) => (
                  <td key={uuid()} style={{ padding: '12px 16px', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                    {index === 0 && hasSelectAllFeature ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox sx={{ mr: 2 }} />
                        {renderCellContent(heading, item)}
                      </Box>
                    ) : (
                      renderCellContent(heading, item)
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={tableHeadingData.length}>
                <Typography
                  level="body-lg"
                  sx={{
                    py: 8,
                    textAlign: 'center',
                    color: 'text.tertiary'
                  }}
                >
                  {t('No Data Found')}
                </Typography>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Sheet>
  );
};

export default CustomTable;