import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import DataTable from "../../common/DataTable";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";
import { useTranslation } from "react-i18next";
import { Box, Chip, Stack } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

const PostList = () => {
  const { pagetype } = useParams()

  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableHeading = [
    {
      accessorKey: "title",
      header: "Title",
      cell: (info) => info.getValue(),
      enableColumnFilter: true,
    },
    {
      accessorKey: "categories",
      header: "Categories",
      cell: (info) => {
        const categories = info.getValue();
        return Array.isArray(categories) ? categories.join(", ") : categories;
      },
      enableColumnFilter: true,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (info) => info.getValue(),
      enableColumnFilter: true,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: ({ row }) => renderActions(row),
      meta: { disableFilter: true },
    },
  ];


  const renderActions = (row) => (
    <div className="flex gap-2">
      <button
        onClick={() => handleView(row.original._id)}
        className="text-blue-500">
        View
      </button>
      <button
        onClick={() => handleEditPost(row.original._id)}
        className="text-green-500">
        Edit
      </button>

    </div>
  );

  const [PostList, setPostList] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]); // State for column filters
  // eslint-disable-next-line
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  //PAGINATION STATE
  const [searchTxt, setsearchTxt] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterState, setFilterState] = useState({});
  // const [limit, setLimit] = useState(0);
  // setLimit(LIMIT);
  const limit = LIMIT;

  const [numberOfTotalData, setNumberOfTotalData] = useState(0);
  const [totalPagesNumber, setTotalPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    let timeout;
    if (!!searchTxt) {
      timeout = setTimeout(() => {
        getListOfPost();
      }, 1500);
    } else {
      getListOfPost();
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchTxt, currentPage, pagetype, columnFilters]);

  const getListOfPost = async () => {
    const { data, success, total, totalPages } = await new APIServices(`post/getalllist/${pagetype}`).post({
      page: currentPage,
      limit: limit,
      search: searchTxt,
      filters: columnFilters,
    });

    if (success) {
      setIsLoading(false);
      setPostList(data);
      setTotalPageNumber(totalPages || 0);
      setNumberOfTotalData(total || 0);
    }
  };

  const handleDeletePost = async () => {
    const { message, success } = await new APIServices(
      `post/${selectedPostId}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      getListOfPost();
    }
  };

  const handleEditPost = (id) => {
    navigate(`/post/edit/${id}`, {
      state: {
        pagetype: pagetype,
      }
    });
  }

  const handleFilterChange = (columnId, value) => {
    setFilterState((prev) => ({
      ...prev,
      [columnId]: value,
    }));
    setCurrentPage(1); // Reset to the first page when filters change
  };

  const handleView = (id) => {
    navigate(`/post/view/${id}`);
  };


  const navigateToAddNewPost = () => {
    navigate("/post/add",
      {
        state: {
          pagetype: pagetype,
        }
      }
    );
  }
  const handleTableSearch = (val) => {
    setsearchTxt(val);
  };

  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        width: '100%',
        maxWidth: '100%',
        pl: { xs: 2, sm: 3, lg: '0px' },
        pr: { xs: 2, sm: 3 },
        pt: {
          xs: 'calc(12px + var(--Header-height))',
          sm: 'calc(12px + var(--Header-height))',
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100dvh',
        gap: 2,
        boxSizing: 'border-box'
      }}
    >
      {/* Breadcrumbs */}
      <Box
        sx={{
          mb: 2,
          maxWidth: '1400px',
          width: '100%'
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            component="a"
            href="/"
            sx={{
              color: '#666666',
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              '&:hover': { color: '#1976d2' }
            }}
          >
            <HomeRoundedIcon sx={{ fontSize: 20 }} />
          </Box>
          <ChevronRightRoundedIcon sx={{ fontSize: 20, color: '#666666' }} />
          <Box
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#1976d2'
            }}
          >
            {t(pagetype)}
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}>

        <div className="w-full">

          <div className="pt-4 dark:bg-gray-900">

            {isLoading ? (
              <TableSkeleton />
            ) : (
              <>
                <TableEntry
                  title={`${t("List of")} ${t(pagetype)} `}
                  navigate={navigateToAddNewPost}
                  handleTableSearch={handleTableSearch}
                />
                <DataTable
                  columns={tableHeading}
                  data={PostList}
                  columnFilters={columnFilters}
                  onColumnFiltersChange={setColumnFilters} // Pass the setter function
                  sx={{ fontSize: '0.8rem', width: '100%', marginTop: '20px' }}
                />
                <TablePagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPagesNumber={totalPagesNumber}
                  numberOfTotalData={numberOfTotalData}
                  limit={limit}
                />
              </>
            )}

          </div>

        </div>
      </Box>

      {showModal && (
        <DeleteModal handleDelete={handleDeletePost} close={setShowModal} />
      )}
    </Box>
  );
};

export default PostList;
