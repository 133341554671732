import React from "react";
import { useTranslation } from "react-i18next";
import { 
  Box, 
  Input, 
  Button, 
  IconButton, 
  Typography, 
  Stack 
} from "@mui/joy";
import AddIcon from '@mui/icons-material/Add';
import ArrowForward from '@mui/icons-material/ArrowForward';

const TableEntry = ({ title, navigate, handleTableSearch, searchTxt }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
        py: 1,
       
        borderColor: 'divider'
      }}
    >
      <Typography level="h4" component="h2">
        {title}
      </Typography>

      {handleTableSearch ? (
        <Stack direction="row" spacing={1} sx={{ flexGrow: 1, maxWidth: 400 }}>
          <Input
            variant="outlined"
            placeholder="Search..."
            onChange={(e) => handleTableSearch(e.target.value)}
            endDecorator={
              <IconButton variant="soft" color="neutral">
                <ArrowForward />
              </IconButton>
            }
            sx={{
              flexGrow: 1,
              '&:hover': {
                borderColor: 'neutral.outlinedHoverBorder'
              }
            }}
          />
          {navigate && (
            <Button
              variant="solid"
              color="primary"
              onClick={navigate}
              startDecorator={<AddIcon />}
              sx={{
                whiteSpace: 'nowrap'
              }}
            >
              Add New
            </Button>
          )}
        </Stack>
      ) : (
        navigate && (
          <Button
            variant="solid"
            color="primary"
            onClick={navigate}
            startDecorator={<AddIcon />}
            sx={{
              whiteSpace: 'nowrap'
            }}
          >
            Add New
          </Button>
        )
      )}
    </Box>
  );
};

export default TableEntry;
