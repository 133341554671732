import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import { LIMIT } from "../../../utilities/constant";
import CustomTable from "../../common/CustomTable";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import TableEntry from "../../common/table/TableEntry";
import TablePagination from "../../common/table/TablePagination";

const ReportList = () => {
    const navigate = useNavigate();
    const tableHeading = ['user', "testName", "part", "comment", 'createdAt', 'status', "Action"];
    const [dataListToShowOnTable, setDataListToShowOnTable] = useState([]);
    //PAGINATION STATE
    const [searchTxt, setsearchTxt] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const limit = LIMIT
    const [numberOfTotalData, setNumberOfTotalData] = useState(0);
    const [totalPagesNumber, setTotalPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let timeout;
        if (!!searchTxt) {
            timeout = setTimeout(() => {
                getListOfDataToShowOnTable();
            }, 1500);
        } else {
            getListOfDataToShowOnTable();
        }
        return () => clearTimeout(timeout);
        // eslint-disable-next-line
    }, [searchTxt, currentPage]);

    const getListOfDataToShowOnTable = async () => {
        const { data, success, total, totalPages } = await new APIServices(
            "report/list"
        ).post({
            page: currentPage,
            limit: limit,
            search: searchTxt,
        });

        if (success) {
            setIsLoading(false);
            setDataListToShowOnTable(data.map((item) => ({
                ...item,
                user: item.userId.firstName + " " + item.userId.lastName,
                createdAt: new Date(item.createdAt).toLocaleString(),
            })));
            setTotalPageNumber(totalPages || 0);
            setNumberOfTotalData(total || 0);
        }
    };


    const handleView = (id) => {
        navigate(`/report/view/${id}`);
    };

    const handleTableSearch = (val) => {
        setsearchTxt(val);
    };

    return (
        <section className="dark:bg-gray-800 ">
            <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
                    {isLoading ? (
                        <TableSkeleton />
                    ) : (
                        <>
                            <TableEntry
                                title="List of Report"
                                handleTableSearch={handleTableSearch}
                            />
                            <CustomTable
                                tableData={dataListToShowOnTable}
                                tableHeadingData={tableHeading}
                                handleDelete={false}
                                handleView={handleView}
                                actionField="_id"
                            />
                            <TablePagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPagesNumber={totalPagesNumber}
                                numberOfTotalData={numberOfTotalData}
                                limit={limit}
                            />
                        </>
                    )}
                </div>
            </div>

        </section>
    );
};

export default ReportList;
