import { ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import APIServices from "../../../httpServices/httpServices";
import { TextError } from "../../common/TextError";

const FormikRelatedPostsSelect = ({
    label,
    name,
    options,
    formik, // Pass formik as a prop
}) => {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedPosts, setSelectedPosts] = useState(formik.values.relatedPosts);
    const [typingTimeout, setTypingTimeout] = useState(null);
    // Initialize selectedPosts from formik.values.relatedPosts when the component mounts
    useEffect(() => {
        if (
            formik.values.relatedPosts &&
            formik.values.relatedPosts.length > 0 &&
            selectedPosts.length === 0 // Only initialize if selectedPosts is empty
        ) {
            const selectedPosts = formik.values.relatedPosts.map((post) => ({
                value: post._id,
                label: post.title,
            }));
            setSelectedPosts(selectedPosts);
        }
    }, [formik.values.relatedPosts]);  // Empty dependency array ensures this runs only once on mount

    // Fetch related posts based on search query
    const fetchRelatedPosts = async (query) => {
        if (query.trim() === "") {
            setSearchResults([]);
            return;
        }
        const { data, success } = await new APIServices("post/fetch-related-posts").post({
            search: query,
        });
        if (success) {
            setSearchResults(
                data.map((post) => ({
                    value: post._id,
                    label: post.title,
                }))
            );
        }
    };

    // Handle search input change with debounce
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        // Clear the previous timeout
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        // Set a new timeout to call the API after 500ms of inactivity
        setTypingTimeout(
            setTimeout(() => {
                fetchRelatedPosts(query);
            }, 1000) // Adjust the delay as needed (e.g., 500ms)
        );
    };

    // Handle selection of posts
    const handlePostSelection = (post) => {
        // Ensure the post object has the correct structure
        if (post && post.value && post.label) {
            if (!selectedPosts.some((p) => p.value === post.value)) {
                const updatedPosts = [...selectedPosts, post];
                setSelectedPosts(updatedPosts);
                // Use formik.setFieldValue directly
                formik.setFieldValue(
                    name,
                    updatedPosts.map((p) => p.value)
                );
            }
            setSearchQuery("");
            setSearchResults([]);
        } else {
            console.error("Invalid post object:", post);
        }
    };

    // Handle removal of a selected post
    const handleRemovePost = (postId) => {
        const updatedPosts = selectedPosts.filter((post) => post.value !== postId);
        setSelectedPosts(updatedPosts);
        // Use formik.setFieldValue directly
        formik.setFieldValue(
            name,
            updatedPosts.map((p) => p.value)
        );
    };

    // Cleanup the timeout on component unmount
    useEffect(() => {
        return () => {
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }
        };
    }, [typingTimeout]);

    return (
        <div>
            <label className="block mb-2 text-sm font-medium" htmlFor={name}>
                {t(label)}
            </label>

            {/* Search Input */}
            <input
                type="text"
                placeholder="Search posts..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-full px-4 py-2 mb-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />

            {/* Search Results Dropdown */}
            {searchResults.length > 0 && (
                <div className="mb-4 border rounded-md shadow-sm max-h-40 overflow-y-auto">
                    {searchResults.map((post) => (
                        <div
                            key={post.value}
                            onClick={() => handlePostSelection(post)}
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                        >
                            {post.label}
                        </div>
                    ))}
                </div>
            )}

            {/* Selected Posts List */}
            <div className="flex flex-wrap gap-2">
                {selectedPosts.map((post) => (
                    <div
                        key={post.value}
                        className="flex items-center px-3 py-1 bg-blue-100 rounded-full"
                    >
                        <span className="mr-2">{post.label}</span>
                        <button
                            type="button"
                            onClick={() => handleRemovePost(post.value)}
                            className="text-red-500 hover:text-red-700"
                        >
                            ×
                        </button>
                    </div>
                ))}
            </div>

            {/* Formik Field */}
            <Field type="hidden" name={name} />
            <ErrorMessage name={name} component={TextError} />
        </div>
    );
};

export default FormikRelatedPostsSelect;