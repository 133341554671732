import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import StatusUpdateModel from "../../common/modals/StatusUpdateModal";
import { Grid, Typography, Box, Button, Sheet} from '@mui/joy';




const StrButton = ({ children, onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        background: "linear-gradient(90deg, #635bff 0%, #8230ff 100%)",
        color: "#fff",
        fontWeight: "bold",
        borderRadius: "8px",
        padding: "5px 30px",
        textTransform: "none",
        fontSize: "14px",
        transition: "0.3s ease",
        "&:hover": {
          background: "linear-gradient(90deg, #4b47e6 0%, #6b1ecf 100%)",
          boxShadow: "0px 4px 12px rgba(99, 91, 255, 0.4)",
        },
        "&:active": {
          transform: "scale(0.98)",
        },
      }}
    >
      {children}
    </Button>
  );
};





const DetailItem = ({ label, value, isDate = false }) => {
    return (
      <Sheet
        variant="soft"
        sx={{
          padding: 2,
          borderRadius: "12px",
          backgroundColor: "background.level1",
          transition: "0.3s",
          
          "&:hover": { boxShadow: "lg" },
        }}
      >
        <Typography
          level="body3"
          sx={{
            fontWeight: 600,
            fontSize: 10,
            mb: 0.5,
            color: "text.tertiary",
            textTransform: "uppercase",
            letterSpacing: 0.5,
          }}
        >
          {label}
        </Typography>
        <Typography
          level="body2"
          sx={{
            fontWeight: isDate ? 700 : 500,
            color: isDate ? "success.500" : "text.primary",
            fontWeight: 300,
            fontSize: 16,
          }}
        >
          {isDate && value ? new Date(value).toLocaleString() : value || "N/A"}
        </Typography>
      </Sheet>
    );
  };
  


const ViewEnrollment = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [itemDetails, setItemDetails] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);

    const statusOptions = [
        { value: "Contacted", label: "Contacted" },
        { value: "Archived", label: "Archived" },
    ];

    useEffect(() => {
        if (id) {
            getItemDetail();
        }
        // eslint-disable-next-line
    }, []);

    const getItemDetail = async () => {
        const { data, success } = await new APIServices(`enrollment/${id}`).get();
        if (success) {
            setItemDetails(data);
        }
    };

    const handleShowStatusModal = () => {
        setShowStatusModal(true);
    };

    const handleShowDeleteModal = () => {
        setShowModal(true);
    };

    const handleDelete = async () => {
        const { message, success } = await new APIServices(`enrollment/${id}`).delete();
        if (success) {
            setShowModal(false);
            emitSuccessToast(message);
            navigate("/enrollment");
        }
    };

    const handleStatus = async (values) => {
        const { message, success } = await new APIServices(`enrollment/${id}`).put({
            contactStatus: values.status,
            remark: values.remark,
        });
        if (success) {
            setShowStatusModal(false);
            emitSuccessToast(message);
            getItemDetail();
            navigate(`/enrollment/view/${id}`);
        }
    };

    return (
        <section className="pb-16 pt-8 bg-white dark:bg-gray-800">
            <div className="max-w-4xl px-4 mx-auto">
                <div className="flex justify-between mb-6">
                <StrButton onClick={handleShowDeleteModal} >Delete</StrButton>
                   
                    <Button
                        variant="solid"
                        color="primary"
                        onClick={handleShowStatusModal}
                        sx={{
                            fontSize: "0.875rem",
                            padding: "8px 16px",
                            fontWeight: 600,
                            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                            ":hover": {
                                boxShadow: "0 6px 12px rgba(0,0,0,0.2)",
                            },
                        }}
                    >
                        Update Status
                    </Button>
                </div>

                {/* Left-Right layout for data */}
                <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6}><DetailItem label="Last Name" value={itemDetails?.lastName} /></Grid>
                    <Grid item xs={12} sm={6}><DetailItem label="Email" value={itemDetails?.email} /></Grid>
                    <Grid item xs={12} sm={6}><DetailItem label="Phone" value={itemDetails?.phone} /></Grid>
                    <Grid item xs={12} sm={6}><DetailItem label="Country Calling Code" value={itemDetails?.countryCallingCode} /></Grid>
                    <Grid item xs={12} sm={6}><DetailItem label="Country Code" value={itemDetails?.countryCode} /></Grid>
                    <Grid item xs={12} sm={6}><DetailItem label="Contact By" value={itemDetails?.contactBy?.join(", ")} /></Grid>
                    <Grid item xs={12} sm={6}><DetailItem label="Best Time To Contact" value={itemDetails?.bestTimeToContact?.join(", ")} /></Grid>
                    <Grid item xs={12} sm={6}><DetailItem label="Contact Status" value={itemDetails?.contactStatus} /></Grid>
                    <Grid item xs={12} sm={6}><DetailItem label="Remark" value={itemDetails?.remark} /></Grid>
                </Grid>

                {/* Modals for Status Update and Delete */}
                {showModal && (
                <DeleteModal handleDelete={handleDelete} close={setShowModal} />
            )}
            {showStatusModal && (

                <StatusUpdateModel
                    statusoptions={statusOptions}
                    handleStatus={handleStatus}
                    close={setShowStatusModal}
                />

   
            )}
            </div>
        </section>
    );
};

export default ViewEnrollment;
