import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import { emitSuccessToast, emitErrorToast } from "../../common/toast/ToastContainer";
import { useSelector } from "react-redux";
import FormikFeedbackRichTextEditor from "../../common/form/FormikFeedbackRichTextEditor";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { marked } from "marked";

const ViewWritingResponse = () => {
    const { id } = useParams();
    const profile = useSelector((state) => state.user.profile);
    const [response, setResponse] = useState(null);
    const [activeTab, setActiveTab] = useState("task1");
    const [scoring, setScoring] = useState({
        contentOrCoherence: 0,
        vocabulary: 0,
        grammar: 0,
        taskFulfillment: 0,
        when: 0,
        totalScore: 0,
        feedback: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isCopying, setIsCopying] = useState(false);
    const [mailSend, setMailSend] = useState(false);

    useEffect(() => {
        const fetchResponse = async () => {
            const { data, success } = await new APIServices(`writing-response/${id}`).get();
            if (success) {
                setResponse(data);
            } else {
                emitErrorToast("Failed to fetch response.");
            }
        };
        fetchResponse();
    }, [id]);
    // Function to get the selected option text for Task 2
    const getSelectedOptionText = () => {
        if (!response || !response.writingTask2QuestionId || !response.task2SelectedOption) {
            return null;
        }

        const selectedOption = response.writingTask2QuestionId.options.find(
            (option) => option._id === response.task2SelectedOption
        );

        return selectedOption ? selectedOption.text : "No option selected.";
    };

    const fetchScoring = async (activeTab) => {
        const { data, success } = await new APIServices(`writing-scoring/getOne/byUser`).post({
            writingResponseId: id,
            userId: profile._id,
        });

        if (success && data) {
            const taskScoresField = activeTab === "task1" ? "task1Scores" : "task2Scores";
            const totalScoreField = activeTab === "task1" ? "task1TotalScore" : "task2TotalScore";
            const feedbackField = activeTab === "task1" ? "task1feedback" : "task2feedback";

            setScoring({
                contentOrCoherence: data[taskScoresField]?.contentOrCoherence || 0,
                vocabulary: data[taskScoresField]?.vocabulary || 0,
                grammar: data[taskScoresField]?.grammar || 0,
                taskFulfillment: data[taskScoresField]?.taskFulfillment || 0,
                when: data[taskScoresField]?.when || 0,
                totalScore: data[totalScoreField] || 0,
                feedback: data[feedbackField] || "",
            });
        } else {
            console.log("Failed to fetch scoring data.");
        }
    };

    useEffect(() => {
        fetchScoring(activeTab); // Pass activeTab as an argument
    }, [id, profile._id, activeTab]); // Add activeTab as a dependency

    const handleTabSwitch = async (tab) => {
        setActiveTab(tab); // Update the active tab
        setScoring({
            contentOrCoherence: 0,
            vocabulary: 0,
            grammar: 0,
            taskFulfillment: 0,
            when: 0,
            totalScore: 0,
            feedback: "",
        }); // Reset scoring state temporarily

        try {
            await fetchScoring(tab); // Pass the new tab value to fetchScoring
        } catch (error) {
            console.error("Failed to fetch scoring data:", error);
        }
    };

    const handleScoringChange = (e) => {
        const { name, value } = e.target;

        setScoring((prev) => {
            const updatedScores = {
                ...prev,
                [name]: parseInt(value),
            };

            return {
                ...updatedScores,
                totalScore:
                    updatedScores.contentOrCoherence +
                    updatedScores.vocabulary +
                    updatedScores.grammar +
                    updatedScores.taskFulfillment +
                    updatedScores.when,
            };
        });
    };

    const handleFeedbackChange = (e) => {
        const { value } = e.target;
        setScoring((prev) => ({
            ...prev,
            feedback: value,
        }));
    };

    const handleSubmitScoring = async () => {
        setIsLoading(true);

        const taskScoresField = activeTab === "task1" ? "task1Scores" : "task2Scores";
        const totalScoreField = activeTab === "task1" ? "task1TotalScore" : "task2TotalScore";
        const feedbackField = activeTab === "task1" ? "task1feedback" : "task2feedback";

        const payload = {
            writingResponseId: id,
            teacherUserId: (profile.role === "Superadmin" || profile.role === "Teacher") ? profile._id : null,
            [taskScoresField]: {
                contentOrCoherence: scoring.contentOrCoherence,
                vocabulary: scoring.vocabulary,
                grammar: scoring.grammar,
                taskFulfillment: scoring.taskFulfillment,
                when: scoring.when,
            },
            [totalScoreField]: scoring.totalScore,
            [feedbackField]: scoring.feedback,
        };

        const { success, message } = await new APIServices("writing-scoring").post(payload);
        if (success) {
            emitSuccessToast(message);
            await fetchScoring(activeTab);
        } else {
            emitErrorToast("Failed to submit scoring.");
        }
    };

    if (!response) {
        return <div>Loading...</div>;
    }

    const getTaskData = (task) => (task === "task1" ? response?.writingTask1QuestionId : response?.writingTask2QuestionId);

    const scoreOptions = Array.from({ length: 13 }, (_, i) => (
        <option key={i} value={i}>
            {i}
        </option>
    ));

    const handleCopy = async () => {
        setIsCopying(true);
        const taskData = getTaskData(activeTab);
        const taskQuestion = taskData?.question;
        const taskScenario = taskData?.scenario;
        const taskInstructions = taskData?.instructions;
        const taskResponse = activeTab === "task1" ? response?.task1responseText : response?.task2responseText;

        const prompt = `Act as Celpip Writing Evaluator, where have to evaluate with writing with the following question of task:

        Question: ${taskQuestion}
        Scenario: ${taskScenario}
        Instructions: ${taskInstructions}

        Response is: ${taskResponse}

        With the following Evaluation Criteria:
        1. Content / Coherence
        Does the response address the question fully and clearly?
        Are ideas well-developed, clear, and logically organized?
        Are examples and supporting details provided?
        Score (1-12): _______
        Feedback: ___________________________________________

        2. Vocabulary
        Is a broad range of vocabulary used accurately and appropriately?
        Are idiomatic expressions or advanced vocabulary incorporated?
        Score (1-12): _______
        Feedback: ___________________________________________

        3. Readability (Grammar / Sentence Structure)
        Is the grammar accurate and appropriate?
        Are sentences varied and fluent, with proper punctuation and spelling?
        Score (1-12): _______
        Feedback: ___________________________________________

        4. Task Fulfillment
        Does the response meet the requirements of the task (e.g., opinion, formal tone)?
        Is the tone appropriate for the audience and context?
        Score (1-12): _______
        Feedback: ___________________________________________

        5. When
        Is the response written in the correct tense?
        Are there any grammatical errors in the response?
        Score (1-12): _______
        Feedback: ___________________________________________

        General Comments:

    Please provide your evaluation in JSON format like:
    {
      "content_coherence": { "score": 9, "feedback": "Feedback here" },
      "vocabulary": { "score": 8, "feedback": "Feedback here" },
      "grammar": { "score": 7, "feedback": "Feedback here" },
      "task_fulfillment": { "score": 10, "feedback": "Feedback here" },
      "when": { "score": 6, "feedback": "Feedback here" },
      "general_comments": "Overall feedback here"
    }`;

        try {
            const result = await sendToGeminiAPI(prompt);
            const cleanedResult = result ? result.replace(/```json|```/g, "").trim() : "";
            // Step 2: Parse cleaned JSON
            const parsedResult = cleanedResult ? JSON.parse(cleanedResult) : {};
            console.log({ parsedResult });
            // Step 3: Update state with structured data

            const returnedFeedback = `
### **Content/Coherence**
${parsedResult.content_coherence?.feedback || "No feedback"}

### **Vocabulary**
${parsedResult.vocabulary?.feedback || "No feedback"}

### **Grammar**
${parsedResult.grammar?.feedback || "No feedback"}

### **Task Fulfillment**
${parsedResult.task_fulfillment?.feedback || "No feedback"}

### **When**
${parsedResult.when?.feedback || "No feedback"}

### **General Comments**
${parsedResult.general_comments || "No comments"}
`
            setScoring({
                contentOrCoherence: parsedResult.content_coherence?.score || 0,
                vocabulary: parsedResult.vocabulary?.score || 0,
                grammar: parsedResult.grammar?.score || 0,
                taskFulfillment: parsedResult.task_fulfillment?.score || 0,
                when: parsedResult.when?.score || 0,
                totalScore:
                    (parsedResult.content_coherence?.score || 0) +
                    (parsedResult.vocabulary?.score || 0) +
                    (parsedResult.grammar?.score || 0) +
                    (parsedResult.task_fulfillment?.score || 0) +
                    (parsedResult.when?.score || 0),
                feedback: marked(returnedFeedback)
            });

            emitSuccessToast("Feedback copied and sent to Gemini API successfully!");
        } catch (error) {
            console.error("Failed to send feedback to Gemini API:", error);
            emitErrorToast("Failed to send feedback to Gemini API.");
        } finally {
            setIsCopying(false);
        }
    };

    const sendToGeminiAPI = async (prompt) => {
        const apiKey = "AIzaSyDLAQlNAO1nl3usF81f8mj2_mafGeQ44tY" // Ensure you have this in your environment variables
        console.log({ apiKey, prompt });
        const genAI = new GoogleGenerativeAI(apiKey);

        const model = genAI.getGenerativeModel({
            model: "gemini-2.0-flash",
        });

        const generationConfig = {
            temperature: 1,
            topP: 0.95,
            topK: 40,
            maxOutputTokens: 8192,
            responseMimeType: "text/plain",
        };

        const chatSession = model.startChat({
            generationConfig,
            history: [],
        });


        const result = await chatSession.sendMessage(prompt);
        return result.response.text();
    };

    const handleSendMail = async () => {
        const { success, message } = await new APIServices('writing-scoring/send-mail').post({
            email: response?.userId?.email,
            subject: 'Feedback for Writing Response',
            body: `
            <p>Dear ${response?.userId?.firstName},</p>

            <p>We have provided feedback on your Writing Response of ${response?.writingGroupId?.title}.</p>

            <p>You can find the detailed review in the Review section on your CELPIP dashboard or click the button below:</p>

            <div style="margin: 20px 0;">
                <a href="https://celpippractice.com/writing/${response?.writingGroupId?.slug}/review" 
                   style="background-color: #4CAF50; border: none; color: white; padding: 12px 24px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 4px;">
                    View Your Feedback
                </a>
            </div>

            <p>If you have any questions, feel free to reach out.</p>

            <p>Best Regards,<br>Celpip Practice</p>
        `,
            isHtml: true // Make sure to include this if your API expects it
        });

        if (success) {
            setMailSend(true);
            emitSuccessToast(message);
        } else {
            setMailSend(false);
            emitErrorToast(message);
        }
    }

    return (
        <div className="max-w-6xl mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-6">View Writing Response</h1>
            <h1 className="text-xl font-bold mb-6">{response?.writingGroupId?.title}</h1>
            <div className="flex border-b border-gray-300 mb-6">
                <button
                    onClick={() => handleTabSwitch("task1")}
                    className={`px-4 py-2 font-medium text-lg ${activeTab === "task1" ? "border-b-2 border-blue-500 text-blue-600" : "text-gray-600"}`}
                >
                    Task 1
                </button>
                <button
                    onClick={() => handleTabSwitch("task2")}
                    className={`px-4 py-2 font-medium text-lg ${activeTab === "task2" ? "border-b-2 border-blue-500 text-blue-600" : "text-gray-600"}`}
                >
                    Task 2
                </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="col-span-2">
                    <div className="bg-white p-6 rounded-lg shadow-md mb-6">
                        <h2 className="text-xl font-semibold mb-4">Task Details</h2>
                        <p><strong>Question:</strong> {getTaskData(activeTab)?.question}</p>
                        <p className="mt-4 whitespace-pre-wrap"><strong>Scenario:</strong> {getTaskData(activeTab)?.scenario}</p>
                        {activeTab === "task1" && (
                            <div className="mt-4">
                                <strong>Instructions:</strong>
                                <div
                                    className="whitespace-pre-wrap"
                                    dangerouslySetInnerHTML={{ __html: getTaskData(activeTab)?.instructions }}
                                />
                            </div>
                        )}
                        {activeTab === "task2" && (
                            <>
                                <p className="mt-4"><strong>Options:</strong></p>
                                <ul className="list-disc list-inside">
                                    {getTaskData(activeTab)?.options?.map((option, index) => (
                                        <li key={index}>{option.text}</li>
                                    ))}
                                </ul>
                                <p className="mt-4"><strong>Chosen Option:</strong> {getSelectedOptionText()}</p>
                            </>
                        )}
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-xl font-semibold mb-4">Response</h2>
                        <pre className="bg-gray-50 p-4 rounded-md whitespace-pre-wrap">
                            {activeTab === "task1" ? response?.task1responseText : response?.task2responseText}
                        </pre>
                        <p className="mt-4"><strong>Word Count:</strong> {activeTab === "task1" ? response?.task1WordCount : response?.task2WordCount}</p>
                        <p className="mt-4"><strong>Submitted By:</strong> {response?.userId?.email}
                            <span className="text-gray-500 text-sm">({response?.userId?.firstName} {response?.userId?.lastName})</span>
                        </p>
                        <p className="mt-4"><strong>Submission Time:</strong> {activeTab === "task1" ? new Date(response?.task1SubmissionTime).toLocaleString() : new Date(response?.task2SubmissionTime).toLocaleString()}</p>
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-4">Scoring</h2>
                    <div className="grid grid-cols-1 gap-4">
                        {[
                            { label: "Content/Coherence", name: "contentOrCoherence" },
                            { label: "Vocabulary", name: "vocabulary" },
                            { label: "Grammar", name: "grammar" },
                            { label: "Task Fulfillment", name: "taskFulfillment" },
                            { label: "When", name: "when" },
                        ].map((field) => (
                            <div key={field.name} className="flex items-center gap-4">
                                <label className="block text-sm font-medium text-gray-700 flex-1">
                                    {field.label} (0-12)
                                </label>
                                <select
                                    name={field.name}
                                    value={scoring[field.name]}
                                    onChange={handleScoringChange}
                                    className="mt-1 block w-24 p-2 border border-gray-300 rounded-md"
                                >
                                    {scoreOptions}
                                </select>
                            </div>
                        ))}
                    </div>

                    <div className="mt-6">
                        <label className="block text-sm font-medium text-gray-700">Total Score</label>
                        <input
                            type="number"
                            value={scoring.totalScore}
                            readOnly
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                        />
                    </div>

                    <div className="mt-6">
                        {/* here response available then only show the button  */}
                        {response && ( // Ensure response is available
                            (activeTab === "task1" && response.task1responseText && response.task1WordCount > 50) ||
                            (activeTab === "task2" && response.task2responseText && response.task2WordCount > 50)
                        ) && (
                                <button
                                    onClick={handleCopy}
                                    disabled={isCopying} // Disable button while loading
                                    className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 flex items-center justify-center"
                                >
                                    {isCopying ? (
                                        <>
                                            <svg
                                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>
                                            Generating...
                                        </>
                                    ) : (
                                        "Copy And Generate"
                                    )}
                                </button>
                            )}
                        <FormikFeedbackRichTextEditor
                            label="Feedback"
                            value={scoring.feedback}
                            onChange={handleFeedbackChange}
                            placeholder="Enter feedback..."
                        />
                    </div>

                    <div className="mt-6">
                        <button
                            onClick={handleSubmitScoring}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                        >
                            Submit Feedback
                        </button>
                    </div>
                    {/* make send mail button */}
                    {!mailSend && activeTab === "task2" && scoring.feedback && (
                        <div className="mt-6">
                            <button
                                onClick={handleSendMail}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                            >
                                Send Mail
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewWritingResponse;