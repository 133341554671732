import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import WritingResponseList from "../writingResponse/WritingResponseList";
import { Box, Typography, Button, Chip, Stack, Divider, Sheet, Badge } from '@mui/joy';
import DeleteModal from "../../common/modals/DeleteModal";

const ViewWritingQuestion = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (id) {
            getDetailView();
        }
        // eslint-disable-next-line
    }, [id]);

    const getDetailView = async () => {
        const { data, success } = await new APIServices(
            `writing-question/${id}`
        ).get();
        if (success) {
            setData(data);
        }
    };

    const handleShowDeleteModal = (id) => {
        setShowModal(true);
    };

    const handleDeleteRole = async () => {
        const { message, success } = await new APIServices(
            `writing-question/${id}`
        ).delete();
        if (success) {
            setShowModal(false);
            emitSuccessToast(message);
            navigate("/writing-question");
        }
    };

    const {
        _id,
        questionType,
        scenario,
        question,
        instructions,
        options,
        status,
        wordLimit,
        timeLimit,
        explanation,
        orderBy,
    } = data;

    return (
        <div className="w-full">
        <Box sx={{ maxWidth: 'lg', mx: 'auto', p: { xs: 2, md: 4 } }}>
            <Stack spacing={3}>
                <Stack 
                    direction="row" 
                    justifyContent="space-between" 
                    alignItems="center"
                >
                    <Typography level="h2" component="h2">
                        Writing Question Information
                    </Typography>
                </Stack>

                <Stack spacing={2}>
                    <Stack 
                        direction={{ xs: 'column', sm: 'row' }} 
                        justifyContent="space-between" 
                        alignItems={{ xs: 'stretch', sm: 'center' }}
                        spacing={2}
                    >
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography level="body1">
                                <strong>Question Type:</strong> {questionType === "EmailWriting" ? "Task 1: Email Writing" : "Opinion Writing"}
                            </Typography>
                            <Typography level="body1">
                                Order {orderBy}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Chip
                                color={status === "Active" ? "success" : "danger"}
                                size="sm"
                            >
                                {status}
                            </Chip>
                            {status === "Inactive" && (
                                <Button
                                    color="danger"
                                    size="sm"
                                    onClick={() => handleShowDeleteModal(_id)}
                                >
                                    Delete
                                </Button>
                            )}
                        </Stack>
                    </Stack>

                   
                </Stack>

                <Sheet
                    variant="outlined"
                    sx={{ p: 3, borderRadius: 'md' }}
                >
                    <Stack spacing={2}>

                    <Typography level="body1">
                        <strong>Question:</strong> {question}
                    </Typography>

                        <Box>
                            <Typography level="body2" fontWeight="bold">Scenario</Typography>
                            <Typography level="body2">{scenario}</Typography>
                        </Box>

                        <Box>
                            <Typography level="body2" fontWeight="bold">Instructions</Typography>
                            <Box 
                            sx={{ 
                                '& p': { margin: 0 },
                                '& ul': { 
                                    paddingLeft: '20px',
                                    listStyleType: 'disc',
                                    '& li': {
                                        marginBottom: '8px'
                                    }
                                },
                                '& ol': { 
                                    paddingLeft: '20px',
                                    listStyleType: 'decimal',
                                    '& li': {
                                        marginBottom: '8px'
                                    }
                                }
                            }}
                            dangerouslySetInnerHTML={{ __html: instructions || '' }}
                        />
                       
                        </Box>

                        <Stack 
                            direction={{ xs: 'column', sm: 'row' }} 
                            spacing={3}
                        >
                            <Box>
                                <Typography level="body2" fontWeight="bold">Word Limit</Typography>
                                <Typography level="body2">{wordLimit}</Typography>
                            </Box>
                            <Box>
                                <Typography level="body2" fontWeight="bold">Time Limit</Typography>
                                <Typography level="body2">{timeLimit}</Typography>
                            </Box>
                        </Stack>

                        {questionType === "EssayWriting" && (
                            <Box>
                                <Typography level="body2" fontWeight="bold">Options</Typography>
                                <Stack spacing={1}>
                                    {options?.map((option) => (
                                        <Typography key={option._id} level="body2">
                                            {option.text}
                                        </Typography>
                                    ))}
                                </Stack>
                            </Box>
                        )}
                    </Stack>
                </Sheet>

                {showModal && (
                    <DeleteModal handleDelete={handleDeleteRole} close={setShowModal} />
                )}

                {/* <WritingResponseList writingQuestionId={_id} /> */}
            </Stack>
        </Box>
        </div>
    );
};

export default ViewWritingQuestion;