import { Container, Sheet, Typography, Tabs, Tab, Box, TabList } from '@mui/joy';
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import SaveButton from "../../common/button/SaveButton";
import FormikTextField from "../../common/form/FormikTextField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import { emitErrorToast, emitSuccessToast } from "../../common/toast/ToastContainer";
import FormikSelectSingleField from '../../common/form/FormikSelectSingleField';
import FormikImageField from '../../common/form/FormikImageField';

const AdInventoryForm = ({ editData }) => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0); // State for tab value

    const initialValues = {
        imageUrl: "",
        title: "",
        description: "",
        destinationUrl: "",
        ctaText: "",
        adFormat: "auto",
        adsTypeId: "",
        adClient: "ca-pub-6166140528266046",
        adSlot: "1278650006",
        fullWidthResponsive: true,
        displayStyle: "block",
        customCSS: "",
        customJS: "",
        adPlacement: "sidebar",
        priority: 0,
        visibility: true,
        clickTracking: true,
    };

    const [form, setForm] = useState({ ...initialValues });
    const [adsTypeOption, setAdsTypeOption] = useState([]);

    useEffect(() => {
        if (editData)
            setForm((prev) => ({
                ...prev,
                ...editData,
            }));
    }, [editData]);

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions;
        let payload = {
            ...values,
            status: values.status ? "Active" : "Inactive",
        };

        let formData = new FormData();

        Object.keys(payload).forEach((key, index) => {
            formData.append(key, payload[key]);
        });

        const { success, data, message } = !!!editData
            ? await new APIServices(`ad-inventory`).post(formData)
            : await new APIServices(`ad-inventory/${editData._id}`).put(formData);

        if (success) {
            emitSuccessToast(message);
            setSubmitting(false);
            resetForm();
            navigate(`/ad-inventory/view/${data?._id}`);
        } else {
            emitErrorToast(message);
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const getAdsType = async () => {
        const { success, data } = await new APIServices(`ads-type/list`).post();
        if (success) {
            setAdsTypeOption(data.map((item) => ({
                label: item.name,
                value: item._id
            })
            ))
        }
    };

    useEffect(() => {
        getAdsType();
    }, []);

    const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value);
    };

    return (
        <Container>
            <Sheet
                variant="outlined"
                sx={{
                    p: 4,
                    borderRadius: 'md',
                    boxShadow: 'lg',
                    bgcolor: 'background.surface',
                }}
            >
                <Typography level="h3" sx={{ mb: 2 }}>
                    Ad Inventory Information
                </Typography>

                <Tabs value={tabValue} onChange={handleTabChange}>
                    <TabList>
                        <Tab>Custom Ad</Tab>
                        <Tab>Google Ad</Tab>
                    </TabList>
                </Tabs>

                <Formik
                    initialValues={form}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form>
                            <Box sx={{ mt: 2 }}>
                                {tabValue === 0 && (
                                    <div className="grid gap-4 mb-4">
                                        {/* <FormikTextField
                                            label="Image URL"
                                            name="imageUrl"
                                            placeholder="Enter image URL"
                                            formik={formik}
                                        /> */}
                                        <FormikImageField label="Image URL" name="imageUrl" formik={formik} />
                                        <FormikTextField
                                            label="Title"
                                            name="title"
                                            placeholder="Enter title"
                                            formik={formik}
                                            required
                                        />
                                        <FormikTextField
                                            label="Description"
                                            name="description"
                                            placeholder="Enter description"
                                            formik={formik}
                                        />
                                        <FormikTextField
                                            label="Destination URL"
                                            name="destinationUrl"
                                            placeholder="Enter destination URL"
                                            formik={formik}
                                            required
                                        />
                                        <FormikTextField
                                            label="CTA Text"
                                            name="ctaText"
                                            placeholder="Enter CTA text"
                                            formik={formik}
                                        />
                                        <FormikSelectSingleField
                                            label="Select ads type"
                                            name="adsTypeId"
                                            options={adsTypeOption}
                                            handleSelectSingle={handleSelectSingle}
                                        />
                                        <FormikTextField
                                            label="Ad Format"
                                            name="adFormat"
                                            placeholder="Enter ad format"
                                            formik={formik}
                                        />
                                        <FormikTextField
                                            label="Ad Placement"
                                            name="adPlacement"
                                            placeholder="Enter ad placement"
                                            formik={formik}
                                        />
                                        <FormikTextField
                                            label="Priority"
                                            name="priority"
                                            placeholder="Enter priority"
                                            formik={formik}
                                            type="number"
                                        />
                                        <FormikSwitchField
                                            label="Visibility"
                                            name="visibility"
                                            formik={formik}
                                        />
                                        <FormikSwitchField
                                            label="Click Tracking"
                                            name="clickTracking"
                                            formik={formik}
                                        />
                                    </div>
                                )}

                                {tabValue === 1 && (
                                    <div className="grid gap-4 mb-4">
                                        <FormikSelectSingleField
                                            label="Select ads type"
                                            name="adsTypeId"
                                            options={adsTypeOption}
                                            handleSelectSingle={handleSelectSingle}
                                        />
                                        <FormikTextField
                                            label="Ad Client"
                                            name="adClient"
                                            placeholder="Enter ad client"
                                            formik={formik}
                                        />
                                        <FormikTextField
                                            label="Ad Slot"
                                            name="adSlot"
                                            placeholder="Enter ad slot"
                                            formik={formik}
                                        />
                                        <FormikSwitchField
                                            label="Full Width Responsive"
                                            name="fullWidthResponsive"
                                            formik={formik}
                                        />
                                        <FormikTextField
                                            label="Display Style"
                                            name="displayStyle"
                                            placeholder="Enter display style"
                                            formik={formik}
                                        />
                                    </div>
                                )}
                            </Box>

                            <SaveButton isSubmitting={formik.isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </Sheet>
        </Container>
    );
};

export default AdInventoryForm;