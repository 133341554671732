import { Container, Sheet, Typography } from '@mui/joy';
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import APIServices from "../../../httpServices/httpServices";
import SaveButton from "../../common/button/SaveButton";
import FormikOnChangeField from "../../common/form/FormikOnChangeField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikTextField from "../../common/form/FormikTextField";
import { emitErrorToast, emitSuccessToast } from "../../common/toast/ToastContainer";
const AdsTypeForm = ({ editData }) => {
    const navigate = useNavigate();
    const initialValues = {
        status: true,
        name: "",
        slug: "",
    };

    const [form, setForm] = useState({ ...initialValues });


    useEffect(() => {
        if (editData)
            setForm((prev) => ({
                ...prev,
                ...editData,
            }));
        // eslint-disable-next-line
    }, [editData]);

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions;

        let payload = {
            ...values,
            status: values.status ? "Active" : "Inactive",
        };


        const { success, data, message } = !!!editData
            ? await new APIServices(`ads-type`).post(payload)
            : await new APIServices(`ads-type/${editData._id}`).put(payload);
        if (success) {
            emitSuccessToast(message);
            setSubmitting(false);
            resetForm();
            navigate(`/ads-type/view/${data?._id}`);
        } else {
            emitErrorToast(message);
        }
    };
    const handleNameChange = (value, form) => {
        form.setFieldValue("name", value);
        form.setFieldValue("slug", slugify(value, { lower: true }));
    };

    return (
        <Container>
            <Sheet
                variant="outlined"
                sx={{
                    p: 4,
                    borderRadius: 'md',
                    boxShadow: 'lg',
                    bgcolor: 'background.surface',
                }}
            >
                <Typography level="h3" sx={{ mb: 2 }}>
                    Ads Type Information
                </Typography>

                <Formik
                    initialValues={form}
                    // validationSchema={QuestionGroupValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form>
                            <div className="grid gap-4 mb-4">

                                <FormikOnChangeField
                                    label="Name"
                                    name="name"
                                    placeholder="Enter a name"
                                    handleChange={handleNameChange}
                                    formik={formik}
                                />
                                <FormikTextField
                                    label="Slug"
                                    name="slug"
                                    formik={formik}
                                    disabled
                                />
                                <FormikSwitchField label="Status" name="status" />
                            </div>
                            <SaveButton isSubmitting={formik.isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </Sheet>
        </Container>
    );
};

export default AdsTypeForm;
