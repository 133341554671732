import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
// import NewDataView from "../../common/viewDetail/NewDataView";
import StatusUpdateModel from "../../common/modals/StatusUpdateModal";


const NewDataView = ({ label, value }) => {
    return (
      <div className="flex items-center justify-between py-3 border-b border-gray-200">
        <span className="text-sm font-medium text-gray-600">{label}</span>
        <span className="text-sm text-gray-900 truncate max-w-xs">
          {value || "N/A"}
        </span>
      </div>
    );
  };


const ViewReport = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [itemDetails, setItemDetails] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);

    const statusOptions = [
        { value: "Fixed", label: "Fixed" },
        { value: "Pending", label: "Pending" },
    ];
    useEffect(() => {
        if (id) {
            getItemDetail();
        }
        // eslint-disable-next-line
    }, []);

    // Fetch User Data
    const getItemDetail = async () => {
        const { data, success } = await new APIServices(`report/${id}`).get();
        if (success) {
            setItemDetails(data);
        }
    };
    const handleShowStatusModal = () => {
        setShowStatusModal(true);
    };
    const handleShowDeleteModal = () => {
        setShowModal(true);
    };

    const handleDelete = async () => {
        const { message, success } = await new APIServices(`report/${id}`).delete();
        if (success) {
            setShowModal(false);
            emitSuccessToast(message);
            navigate("/report");
        }
    };
    const handleStatus = async (values) => {
        const { message, success } = await new APIServices(`report/${id}`).put({
            status: values.status,
            remark: values.remark,
        });
        if (success) {
            setShowStatusModal(false);
            emitSuccessToast(message);
            getItemDetail();
            navigate(`/report/view/${id}`);
        }
    };

    return (
        <section className="pb-16 pt-8 bg-white dark:bg-gray-800">
        <div className="px-6 mx-auto max-w-6xl min-w-6xl">
        {/* Button */}
        <button
            onClick={handleShowStatusModal}
            className="inline-flex items-center px-5 py-2.5 bg-indigo-600 text-white text-sm font-medium tracking-tight uppercase rounded-lg shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-150 mb-6"
        >
            Update Status
        </button>

        {/* Data Views */}
        <div className="space-y-4">
            <NewDataView
            label="User"
            value={`${itemDetails?.userId?.firstName} ${itemDetails?.userId?.lastName}`}
            />
            <NewDataView label="Email" value={itemDetails?.userId?.email} />
            <NewDataView label="Test Name" value={itemDetails?.testName} />
            <NewDataView label="Part" value={itemDetails?.part} />
            <NewDataView label="Comment" value={itemDetails?.comment} />
            <NewDataView label="Status" value={itemDetails?.status} />
            <NewDataView label="Remark" value={itemDetails?.remark} />
            <NewDataView
            label="Created At"
            value={new Date(itemDetails?.createdAt).toLocaleString()}
            />
            <NewDataView
            label="Updated At"
            value={new Date(itemDetails?.updatedAt).toLocaleString()}
            />
        </div>
        </div>
            {showModal && (
                <DeleteModal handleDelete={handleDelete} close={setShowModal} />
            )}
            {showStatusModal && (
                <StatusUpdateModel
                    statusoptions={statusOptions}
                    handleStatus={handleStatus}
                    close={setShowStatusModal}
                />
            )}
        </section>
    );
};

export default ViewReport;
