import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import FormikTextAreaField from "../form/FormikTextAreaField";
import FormikSelectSingleField from "../form/FormikSelectSingleField";
import { t } from "i18next";

const StatusUpdateModel = ({ statusoptions, close, handleStatus, }) => {
    const [initialValues, setInitialValues] = useState({
        status: "",
        remark: "",
    });

    useEffect(() => {
        setInitialValues({ ...initialValues });
        // eslint-disable-next-line
    }, []);


    const handleSubmit = (values) => {
        handleStatus(values)

    };
    const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value);
    };

    return (
        <>
            <div
                className="fixed inset-0 bg-gray-800/80 z-[98]"
                onClick={() => close(false)}
            ></div>
            <div
                id="popup-modal"
                className="fixed top-1/2 z-[99] -translate-x-1/2 -translate-y-1/2 left-1/2 p-4 overflow-x-hidden overflow-y-auto"
            >
                <div className="relative w-full h-full ">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button
                            onClick={() => close(false)}
                            className="absolute top-3 right-2.5 text-white hover:bg-red-700 bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-toggle="popup-modal"
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>

                        <div className="p-6">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                enableReinitialize
                            >
                                {(formik) => (
                                    <Form>
                                        <FormikSelectSingleField
                                            label="Select status"
                                            name="status"
                                            options={statusoptions}
                                            handleSelectSingle={handleSelectSingle}
                                        />
                                        <FormikTextAreaField
                                            label="Write remark"
                                            name="remark"
                                            formik={formik}
                                        />
                                        <div className="flex justify-between mt-4">
                                            <button
                                                type="submit"
                                                data-modal-toggle="popup-modal"
                                                className={`text-white bg-blue-500  hover:bg-blue-600 focus:ring-blue-300  focus:ring-4 focus:outline-none dark:focus:ring-blue-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2`}
                                            >
                                                Update
                                            </button>

                                            <button
                                                data-modal-toggle="popup-modal"
                                                onClick={() => close(false)}
                                                className=" bg-red-600 hover:bg-red-700 text-white focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 text-center focus:z-10"
                                            >
                                                {t('Cancel')}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatusUpdateModel;
