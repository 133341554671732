import React from 'react'
import PostForm from '../../container/post/PostForm'
import { useLocation } from 'react-router-dom';

const AddPost = () => {
    const { state } = useLocation();
    const { pagetype } = state;
    return (
        <PostForm pagetype={pagetype} />
    )
}

export default AddPost
