import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { TextError } from "../../common/TextError";
import APIServices from "../../../httpServices/httpServices";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import slugify from "slugify";
import FormikTextField from "../../common/form/FormikTextField";
import FormikTextAreaField from "../../common/form/FormikTextAreaField";
// import FormikDateField from "../../common/form/FormikDateField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikNumberField from "../../common/form/FormikNumberField";
import FormikImageField from "../../common/form/FormikImageField";
import FormikRichTextEditor from "../../common/form/FormikRichTextEditor";
import { useNavigate } from "react-router-dom";
import PostValidationSchema from "../../../validation/PostValidationSchema";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import moment from "moment";
import FormikDateTimePickerField from "../../common/form/FormikDateTimePickerField";
import FormikCreatableSelect from "../../common/form/FormikCreatableSelect";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SaveButton from "../../common/button/SaveButton";
import TinyPassageEditor from "../../common/richTextEditor/TinyPassageEditor";
import { Sheet } from "@mui/joy";
import { Card, Stack } from "@mui/material";
import { Button, Input, FormControl, FormLabel, FormHelperText, CircularProgress, Box, Typography, Skeleton } from '@mui/joy';
import FormikRelatedPostsSelect from "./FormikRelatedPostsSelect";
import FormikTagCreateTableSelect from "../../common/form/FormikTagCreateTableSelect";



const PostForm = ({ data: postData, id, pagetype }) => {
  console.log({ postData })
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pageTypeOptions, setPageTypeOptions] = useState([]);
  const { profile } = useSelector((state) => state.user);
  const [slugExist, setSlugExist] = useState(false);
  const [pageType, setPageType] = useState(null);
  const [toggleSlug, setToggleSlug] = useState(false);
  const [tagListOptions, setTagListOptioins] = useState([]);
  const [categoryListOptions, setCategoryListOptioins] = useState([]);
  const [initialValues, setInitialValues] = useState({
    status: true,
    title: "",
    slug: "",
    pageType: pagetype,
    featuredImage: "",
    publishedOn: "",
    nextReviewDate: "",
    tags: [],
    categories: [],
    redirectTo: "",
    remark: "",
    isVerified: "",
    orderBy: "",
    summary: "",
    detail: "",
    seoTitle: "",
    seoDescription: "",
    relatedPosts: [],
  });
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const initializeForm = async () => {
      try {
        // Load all data in parallel
        await Promise.all([
          getFormSelectFieldValues(),
          // Add any other data fetching here
        ]);

        // Set initial values after all data is loaded
        if (postData) {
          setInitialValues((prev) => ({
            ...prev,
            ...postData,
            tags: postData?.tags?.map((item) => item._id),
          }));
        }

        // Add a small delay to simulate server-side rendering
        await new Promise(resolve => setTimeout(resolve, 500));

        // Set both states at once to prevent flickering
        setIsReady(true);
        setIsLoading(false);
      } catch (error) {
        console.error('Error initializing form:', error);
        setIsLoading(false);
      }
    };

    initializeForm();
  }, [postData]);

  useEffect(() => {
    if (!!!id) {
      setToggleSlug(true);
    }
    // eslint-disable-next-line
  }, [id]);

  const handleFormikFormSubmit = async (values, actions) => {
    let bool = await validateSlug(values?.slug);
    if (!bool) {
      let oldTags = postData?.tags;
      console.log({ oldTags })
      let tagArrToRemove = [];
      let oldCategories = postData?.categories;
      let categoryArrToRemove = [];
      let { setSubmitting } = actions;

      let payload = {
        ...values,
        status: values.status ? "Active" : "Inactive",
        isVerified: values.isVerified ? "Yes" : "No",
        modifiedOn: moment().format(),
      };
      const { tags, categories, seoDescription, seoTitle, ...rest } = payload;
      console.log({ payload })
      tagArrToRemove = oldTags?.filter((item) => !tags.includes(item));
      categoryArrToRemove = oldCategories?.filter((item) => !categories.includes(item));
      let formData = new FormData();
      formData.append("tags", JSON.stringify([...new Set(tags)]));
      formData.append("categories", JSON.stringify([...new Set(categories)]));
      formData.append("seoTitle", seoTitle || payload.title);
      formData.append(
        "seoDescription",
        seoDescription || payload.summary || payload.title
      );

      Object.keys(rest).forEach((key, index) => {
        formData.append(key, rest[key]);
      });
      const { success, data, message } = !!!id
        ? await new APIServices(`post`).post(formData)
        : await new APIServices(`post/${id}`).put(formData);

      if (success) {
        setSubmitting(false);
        // insertTags([...new Set(tags)], [...new Set(tagArrToRemove)]);
        insertCategories([...new Set(categories)], [...new Set(categoryArrToRemove)]);
        emitSuccessToast(message);
        navigate(`/post/view/${data.slug}`, { replace: true });
      }
    }
  };

  const getFormSelectFieldValues = async () => {
    const [pageTypeObj, tagObj, categoryObj] = await Promise.all([
      new APIServices("setting/get/page-type").get(),
      new APIServices("tag/list").post(),
      new APIServices("category/list").post(),
    ]);
    if (pageTypeObj.success) {
      const { settingValue } = pageTypeObj.data;
      let options = JSON.parse(settingValue)?.map((item) => ({
        value: item,
        label: t(item),
      }));
      setPageTypeOptions(options);
    }
    if (tagObj.success && !!tagObj?.data?.length) {
      let options = tagObj?.data?.map((item) => ({
        value: item._id,
        label: item.title,
      }));
      setTagListOptioins(options);
    }
    if (categoryObj.success && !!categoryObj?.data?.length) {
      let options = categoryObj?.data?.map((item) => ({
        value: item.title,
        label: item.title,
      }));
      setCategoryListOptioins(options);
    }
  };
  console.log({ tagListOptions })

  const insertTags = async (tagArr, tagArrToRemove = []) => {
    let tagMap = new Map(); // Map to store tag titles and their corresponding _id
    let tagToInsert = [];
    let alreadyHaveTag = [];

    // Fetch existing tags
    const { data, success } = await new APIServices("tag/list").post();
    if (success) {
      // Create a map of tag titles to their _id
      data.forEach((item) => {
        tagMap.set(item.title, item._id);
      });

      console.log({ tagMap, tagArr, tagArrToRemove })
      // Separate tags that need to be inserted and those that already exist
      tagArr.forEach((item) => {
        if (tagMap.has(item)) {
          alreadyHaveTag.push(tagMap.get(item)); // Push the _id of the existing tag
        } else {
          tagToInsert.push(item); // Push the title of the new tag
        }
      });
    }

    // Insert new tags
    if (tagToInsert.length) {
      const promises = tagToInsert.map(async (item) => {
        let payload = {
          status: "Inactive",
          title: item,
          slug: slugify(item, { lower: true }),
          Image: "",
          SpecialValue: "",
          TagType: null,
          detail: "",
          seoTitle: "",
          seoDescription: "",
          PostIds: [postData?._id],
        };
        const response = await new APIServices("tag").post(payload);
        return response.data._id; // Return the _id of the newly created tag
      });

      const newTagIds = await Promise.all(promises);
      alreadyHaveTag.push(...newTagIds); // Add the new tag _ids to the alreadyHaveTag array
    }

    // Update existing tags with the postId
    if (alreadyHaveTag.length) {
      const promises = alreadyHaveTag.map(async (tagId) => {
        return await new APIServices("tag/postIds/add").post({
          tagId: tagId, // Use _id instead of title
          postId: postData?._id,
        });
      });
      await Promise.all(promises);
    }

    // Remove tags from the post
    if (tagArrToRemove.length) {
      const promises = tagArrToRemove.map(async (item) => {
        return await new APIServices("tag/postIds/remove").post({
          tagId: tagMap.get(item), // Use _id instead of title
          postId: postData?._id,
        });
      });
      await Promise.all(promises);
    }
  };

  const insertCategories = async (categoryArr, categoryArrToRemove = []) => {
    let categoryTitleList = [];
    let categoryToInsert = [];
    let alreadyHaveCategory = [];
    const { data, success } = await new APIServices("category/list").post();
    if (success) {
      categoryTitleList = data.map((item) => item.title);
      categoryArr.forEach((item) => {
        if (categoryTitleList.includes(item)) {
          alreadyHaveCategory.push(item);
        } else {
          categoryToInsert.push(item);
        }
      });
    }

    if (categoryToInsert.length) {
      const promises = categoryToInsert.map(async (item) => {
        let payload = {
          status: "Inactive",
          title: item,
          slug: slugify(item, { lower: true }),
          Image: "",
          SpecialValue: "",
          CategoryType: null,
          detail: "",
          seoTitle: "",
          seoDescription: "",
          PostIds: [postData?._id],
        };
        return await new APIServices("category").post(payload);
      });
      await Promise.all(promises);
    }

    if (alreadyHaveCategory.length) {
      const promises = alreadyHaveCategory.map(async (item) => {
        return await new APIServices("category/postIds/add").post({
          categoryTitle: item,
          postId: postData?._id,
        });
      });
      await Promise.all(promises);
    }
    if (categoryArrToRemove.length) {
      const promises = categoryArrToRemove.map(async (item) => {
        return await new APIServices("category/postIds/remove").post({
          categoryTitle: item,
          postId: postData?._id,
        });
      });
      await Promise.all(promises);
    }
  };

  // const handleSelectMulti = (selectedOption, fieldName, setFieldValue) => {
  //   let val = selectedOption.map((opt) => {
  //     let str = opt.value;
  //     return str[0].toUpperCase() + str.slice(1);
  //   });
  //   setFieldValue(fieldName, val);
  // };
  const handleSelectMulti = (selectedOption, fieldName, setFieldValue) => {
    const selectedValues = selectedOption.map((opt) => opt.value); // Extract only the IDs
    setFieldValue(fieldName, selectedValues);
  };

  const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
    setFieldValue(fieldName, selectedOption.value);
    setPageType(selectedOption.value);
  };

  // Async Validation
  const validateSlug = async (slug) => {
    let payload = {
      slug: slug,
      pageType: pageType,
    };
    const { success } = await new APIServices("post/check-slug-exist").post(
      payload
    );
    if (success) {
      setSlugExist(success);
      return true;
    } else {
      return false;
    }
  };
  const customSlugify = (text) => {
    return text
      .toString() // Convert to string (in case it's not)
      .toLowerCase() // Convert to lowercase
      .replace(/[:|]/g, '') // Remove colons and pipes
      .replace(/[^\w\s-]/g, '') // Remove all non-word characters (except spaces and hyphens)
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/--+/g, '-') // Replace multiple hyphens with a single hyphen
      .trim(); // Trim leading and trailing spaces
  };
  const handleTitleChange = (e, field, form) => {
    if (slugExist) {
      setSlugExist(false);
    }
    form.setFieldValue(field.name, e.target.value);
    if (toggleSlug) {
      form.setFieldValue("slug", customSlugify(e.target.value, { lower: true }));
    }
  };

  const handleSlugChange = (e, field, form) => {
    if (slugExist) {
      setSlugExist(false);
    }
    form.setFieldValue("slug", customSlugify(e.target.value, { lower: true }));
  };

  const handleToggleSlug = () => {
    setToggleSlug(!toggleSlug);
  };

  // Update the loading component
  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white/80 backdrop-blur-sm">
        <div className="text-center">
          <CircularProgress size="lg" />
          <p className="mt-4 text-gray-600">Loading form data...</p>
        </div>
      </div>
    );
  }

  // Don't render anything until everything is ready
  if (!isReady) {
    return null;
  }

  return (
    <Sheet
      sx={{
        p: 0,
        maxWidth: "100%",
        width: "100%",
        opacity: isLoading ? 0 : 1,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <div className="max-w-6xl px-4 mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={PostValidationSchema}
          onSubmit={handleFormikFormSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <div className="grid grid-cols-[70%,1fr] gap-4">
                <div>
                  <div className="mainbox">
                    {/* Title Field */}
                    <div className="mb-6">
                      <label className="block mb-2 text-sm font-medium">
                        {t("Title")} *
                      </label>
                      <Field
                        type="text"
                        name="title"
                        placeholder="Enter a title"
                      >
                        {({ field, form, meta }) => (
                          <input
                            name="title"
                            type="Text"
                            placeholder="Enter a title"
                            value={field.value || ""}
                            onChange={(e) => handleTitleChange(e, field, form)}
                            onFocus={(e) => {
                              setToggleSlug(true);
                            }}
                            onBlur={(e) => {
                              form.setTouched({
                                ...form.touched,
                                [field.name]: true,
                              });
                              form.handleBlur(e);
                              setToggleSlug(false);
                            }}
                            className={`block w-full px-4 py-3 text-sm placeholder-gray-500 bg-white border rounded dark:border-gray-800 dark:bg-gray-800 ${formik.touched.title && formik.errors.title
                              ? "border-red-600"
                              : null
                              }`}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="title" component={TextError} />
                    </div>

                    {/* Slug Field */}
                    <div className="mb-6">
                      <FormControl >
                        <FormLabel sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          {t("Slug")} *
                          <Button
                            variant="soft"
                            color="primary"
                            onClick={handleToggleSlug}
                            size="sm"
                            sx={{ textTransform: 'none' }}
                          >
                            {t("Change slug")}
                          </Button>
                        </FormLabel>

                        <Field
                          type="text"
                          name="slug"
                          placeholder={t("Enter a title")}
                        >
                          {({ field, form, meta }) => (
                            <Input
                              {...field}
                              value={field.value || ""}
                              onChange={(e) => handleSlugChange(e, field, form)}
                              onBlur={(e) => form.handleBlur(e)}
                              readOnly={toggleSlug ? false : true}
                              sx={{
                                '&.MuiInput-root': {
                                  padding: '12px',
                                  fontSize: '0.875rem',
                                  borderColor: form.touched.slug && (form.errors.slug || slugExist) ? 'red' : undefined,
                                  backgroundColor: 'white',
                                },
                                '&::placeholder': {
                                  visibility: field.value ? 'hidden' : 'visible',
                                },
                              }}
                            />
                          )}
                        </Field>

                        {slugExist ? (
                          <FormHelperText sx={{ color: 'red', fontSize: '0.75rem', marginTop: 1 }}>
                            {t('slug already exists.')}
                          </FormHelperText>
                        ) : (
                          <ErrorMessage name="slug" component={TextError} />
                        )}
                      </FormControl>
                    </div>



                    {/* Detail Field */}
                    <div className="mb-6">
                      <label>Detail</label>
                      <TinyPassageEditor
                        fieldname="detail"
                        initialValue={formik.values.detail}
                        setFieldValue={formik.setFieldValue}
                      />
                    </div>


                    {/* Summary Field */}
                    <div className="mb-6">
                      <FormikTextAreaField
                        label="Summary"
                        name="summary"
                        formik={formik}
                      />
                    </div>
                    <FormikRelatedPostsSelect
                      label="Related Posts"
                      name="relatedPosts"
                      formik={formik} // Pass the formik object
                    />
                    {/* SEO Section */}
                    <div className="p-6 mb-4 bg-white rounded-md shadow">
                      <div className="mb-6">
                        <FormikTextField
                          label="Seo title"
                          name="seoTitle"
                          type="text"
                          formik={formik}
                        />
                      </div>
                      <div className="">
                        <FormikTextAreaField
                          label="Seo Description"
                          name="seoDescription"
                          formik={formik}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Sidebar */}
                <div className="">
                  <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
                    <FormikSwitchField label="Status" name="status" formik={formik} />
                  </Card>

                  <Stack spacing={2}>
                    <Card variant="outlined" sx={{ p: 2 }}>
                      <FormikSwitchField label="Is Verified" name="isVerified" formik={formik} />
                    </Card>

                    <Card variant="outlined" sx={{ p: 2 }}>
                      {!!!id && (
                        <FormikDateTimePickerField
                          label="Published On"
                          name="publishedOn"
                          formik={formik}
                        />
                      )}

                      {id && (
                        <FormikSelectSingleField
                          label="Page Type *"
                          name="pageType"
                          options={pageTypeOptions}
                          handleSelectSingle={handleSelectSingle}
                        />
                      )}

                      <FormikNumberField label="Order By" name="orderBy" formik={formik} />
                    </Card>

                    <Card variant="outlined" sx={{ p: 2 }}>
                      <FormikImageField
                        label="Featured Image"
                        name="featuredImage"
                        formik={formik}
                      />
                    </Card>

                    <Card variant="outlined" sx={{ p: 2, overflow: "initial" }}>
                      <FormikCreatableSelect
                        label="Categories"
                        name="categories"
                        options={categoryListOptions}
                        handleSelectOption={handleSelectMulti}
                      />
                    </Card>

                    <Card variant="outlined" sx={{ p: 2, overflow: "initial" }}>
                      <FormikTagCreateTableSelect
                        label="Tags"
                        name="tags"
                        options={tagListOptions}
                        handleSelectOption={handleSelectMulti}
                      />
                    </Card>
                  </Stack>
                </div>
              </div>

              {/* Submit Button */}
              <div className="mt-7">
                <div className="flex justify-start space-x-2">
                  <SaveButton isSubmitting={formik.isSubmitting} label={"Save"} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Sheet>
  );
};

export default PostForm;
