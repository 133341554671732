import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
import WritingQuestionList from "../writingQuestion/WritingQuestionList";
import {
  Card,
  Chip,
  Typography,
  Button,
  Stack,
  Grid,
  CircularProgress
} from "@mui/joy";
import { Circle } from "@mui/icons-material";

const ViewWritingGroup = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id) {
            getDetailView();
        }
    }, [id]);

    const getDetailView = async () => {
        setLoading(true);
        try {
            const { data, success } = await new APIServices(
                `writing-group/${id}`
            ).get();
            if (success) setData(data);
        } finally {
            setLoading(false);
        }
    };

    const handleShowDeleteModal = () => setShowModal(true);

    const handleDeleteRole = async () => {
        const { message, success } = await new APIServices(
            `writing-group/${id}`
        ).delete();
        if (success) {
            setShowModal(false);
            emitSuccessToast(message);
            navigate("/writing-group");
        }
    };

    if (loading || !data) {
        return (
            <Stack sx={{ height: '50vh' }} alignItems="center" justifyContent="center">
                <CircularProgress size="lg" />
                <Typography level="body-md" sx={{ mt: 2 }}>
                    Loading writing group...
                </Typography>
            </Stack>
        );
    }

    const { _id, title, detail, status, level, writingQuestions = [] } = data;
    const hasEmailWritingQuestion = writingQuestions.some(q => q.questionType === "EmailWriting");
    const hasEssayWritingQuestion = writingQuestions.some(q => q.questionType === "EssayWriting");

    return (
        <div className="w-full">            
        <Stack spacing={3} sx={{ maxWidth: 'lg', mx: 'auto', p: 3 }}>
            {/* Header Section */}
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography level="h3" component="h1">
                    Writing Group Information
                </Typography>
                
                <Stack direction="row" spacing={2} alignItems="center">
                    <Chip
                        variant="soft"
                        color={status === "Active" ? "success" : "danger"}
                        startDecorator={<Circle sx={{ fontSize: '0.8rem' }} />}
                    >
                        {status}
                    </Chip>
                    
                    {status === "Inactive" && (
                        <Button
                            variant="solid"
                            color="danger"
                            onClick={handleShowDeleteModal}
                        >
                            Delete Group
                        </Button>
                    )}
                </Stack>
            </Stack>

            {/* Main Content Card */}
            <Card variant="outlined">
                <Grid container spacing={3}>
                    <Grid xs={12} md={4}>
                        <DataView 
                            label="Title" 
                            value={<Typography level="body-lg">{title}</Typography>}
                        />
                    </Grid>
                    
                    <Grid xs={12} md={4}>
                        <DataView 
                            label="Level" 
                            value={
                                <Chip variant="soft" color="primary" size="lg">
                                    {level}
                                </Chip>
                            }
                        />
                    </Grid>
                    
                    <Grid xs={12}>
                        <DataView 
                            label="Details" 
                            value={<Typography level="body-md">{detail}</Typography>}
                        />
                    </Grid>
                </Grid>
            </Card>

            {/* Action Buttons */}
            <Stack direction="row" spacing={1}>
                {!hasEmailWritingQuestion && (
                    <Button
                        component={Link}
                        to={`/writing-question/${_id}/EmailWriting`}
                        variant="solid"
                        color="primary"
                    >
                        Add Task 1 Question
                    </Button>
                )}
                
                {!hasEssayWritingQuestion && (
                    <Button
                        component={Link}
                        to={`/writing-question/${_id}/EssayWriting`}
                        variant="solid"
                        color="primary"
                    >
                        Add Task 2 Question
                    </Button>
                )}
            </Stack>

            {/* Question List */}
            <WritingQuestionList writingGroupId={_id} />

            {/* Delete Modal */}
            {showModal && (
                <DeleteModal 
                    handleDelete={handleDeleteRole} 
                    close={() => setShowModal(false)}
                />
            )}
        </Stack>
        </div>
    );
};

export default ViewWritingGroup;