import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import APIServices from "../../../httpServices/httpServices";
import QuestionGroupValidationSchema from "../../../validation/QuestionGroupValidationSchema";
import DashCard from "../../common/DashCard";
import FormHeading from "../../common/FormHeading";
import SaveButton from "../../common/button/SaveButton";
import FormikOnChangeField from "../../common/form/FormikOnChangeField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikTextField from "../../common/form/FormikTextField";
import { emitErrorToast, emitSuccessToast } from "../../common/toast/ToastContainer";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import FormikImageField from "../../common/form/FormikImageField";
import { Container, Typography, Sheet } from '@mui/joy';
const WritingGroupForm = ({ editData }) => {
    const navigate = useNavigate();
    const initialValues = {
        status: true,
        title: "",
        slug: "",
        detail: "",
        level: "Easy",
        pricingType: "Free",
        metaTitle: "",
        metaDescription: "",
        image: "",
        metaKeywords: "",
        jsonldStructuredData: "",
    };

    const [form, setForm] = useState({ ...initialValues });

    const level = ["Easy", "Medium", "Hard"];

    let levelOptions = level.map((d) => ({ value: d, label: d }));

    const pricingType = ["Free", "Paid"];
    let pricingTypeOptions = pricingType.map((d) => ({ value: d, label: d }));

    useEffect(() => {
        if (editData)
            setForm((prev) => ({
                ...prev,
                ...editData,
            }));
        // eslint-disable-next-line
    }, [editData]);

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions;

        let payload = {
            ...values,
            status: values.status ? "Active" : "Inactive",
        };

        let formData = new FormData();
        Object.keys(payload).forEach((key, index) => {
            formData.append(key, payload[key]);
        });

        const { success, data, message } = !!!editData
            ? await new APIServices(`writing-group`).post(formData)
            : await new APIServices(`writing-group/${editData._id}`).put(formData);
        if (success) {
            emitSuccessToast(message);
            setSubmitting(false);
            resetForm();
            navigate(`/writing-group/view/${data?._id}`);
        } else {
            emitErrorToast(message);
        }
    };
    const handleNameChange = (value, form) => {
        form.setFieldValue("title", value);
        form.setFieldValue("slug", slugify(value, { lower: true }));
    };
    const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value);
    };
    return (
        <Container>
            <Sheet
                variant="outlined"
                sx={{
                    p: 4,
                    borderRadius: 'md',
                    boxShadow: 'lg',
                    bgcolor: 'background.surface',
                }}
            >
                <Typography level="h3" sx={{ mb: 2 }}>
                    Writing Group Information
                </Typography>

                <Formik
                    initialValues={form}
                    validationSchema={QuestionGroupValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form>
                            <div className="grid gap-4">
                              
                                    <FormikOnChangeField
                                        label="Title"
                                        name="title"
                                        placeholder="Enter a title"
                                        handleChange={handleNameChange}
                                        formik={formik}
                                    />
                                    <FormikTextField
                                        label="Slug"
                                        name="slug"
                                        formik={formik}
                                        disabled
                                    />
                                    <FormikTextField
                                        label="Detail"
                                        name="detail"
                                        as="textarea"
                                        placeholder="Please write some detail"
                                        formik={formik}
                                    />
                                    <FormikSelectSingleField
                                        label="Select level"
                                        name="level"
                                        options={levelOptions}
                                        handleSelectSingle={handleSelectSingle}
                                    />
                                    <FormikSelectSingleField
                                        label="Select Pricing Type"
                                        name="pricingType"
                                        options={pricingTypeOptions}
                                        handleSelectSingle={handleSelectSingle}
                                    />
                                    <FormikTextField
                                        label="Meta Title"
                                        name="metaTitle"
                                        formik={formik}
                                    />
                                    <FormikTextField
                                        label="Meta Description"
                                        name="metaDescription"
                                        as="textarea"
                                        formik={formik}
                                    />
                                    <FormikTextField
                                        label="Meta Keywords (Put in Comma separated)"
                                        name="metaKeywords"
                                        as="textarea"
                                        formik={formik}
                                    />
                                    <FormikImageField label="Featured Image" name="image" />
                                    <FormikTextField
                                        label="Jsonld Structured Data"
                                        name="jsonldStructuredData"
                                        formik={formik}
                                        as="textarea"
                                        rows={20}
                                        placeholder="Please write Jsonld Structured Data"
                                    />
                                    <FormikSwitchField label="Status" name="status" />
                              
                            </div>
                            <SaveButton isSubmitting={formik.isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </Sheet>
        </Container>
    );
};

export default WritingGroupForm;
